// Contact.js
import React from 'react'
import { resetTitle } from '../components/main'

const Error = () => {
  resetTitle('Error 404')
  return (
    <div className="error container text-center">
      <h1>404</h1>
      <p>
        Entschuldigung, die von Ihnen gesuchte Seite konnte <br />
        leider nicht gefunden werden.
      </p>
      <a href="/">Zurück zu Home</a>
    </div>
  )
}

export default Error
