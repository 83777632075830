import { Offcanvas } from "bootstrap"
function resetTitle(title) {
    document.title = title;
}

const IMPRESSUM = {
    "tel": "0234-5884498",
    "city": "Bochum",
    "pc": "44795",
    "street" : "Kohlenstr. 36",
    "email": "info@rueckhalt.net"
}

function openOffcanvas() {
    var offcanvasElement = document.getElementById("offcanvas")
    var offcanvas = new Offcanvas(offcanvasElement)
    offcanvas.show()
  }
  
function closeOffcanvas() {
    document.getElementById("close").click()
}

export {resetTitle, IMPRESSUM, closeOffcanvas, openOffcanvas};