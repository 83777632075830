// import React from 'react';
import { IMPRESSUM } from "./main"

import footerimage1 from "../images/webp/footer-img-1.webp"
import footerimage2 from "../images/webp/footer-img-2.webp"

function Footer() {
  return (
    <footer className="bg-p-1">
      <div className="impressum container text-center">
        <div className="row">
          <div className="col">
            <div className="block-impressum">
              <h3>ANSCHRIFT</h3>
              <p>
                Praxis für Manuelle <br /> Therapie Rueckhalt
              </p>
              <p>
                {IMPRESSUM.street}
                <br />
                {IMPRESSUM.pc} {IMPRESSUM.city} <br />
                <br />
                <a type="button" href={"tel:" + IMPRESSUM.tel} className="a">
                  {IMPRESSUM.tel}
                </a>
                <br />
                <a className="a" href={"mailto:" + IMPRESSUM.email}>
                  {IMPRESSUM.email}
                </a>
              </p>
            </div>
          </div>
          <div className="col mf-middle">
            <h3>TERMINVERGABE</h3>
            <p>Bitte rufen Sie uns an und vereinbaren Sie einen Termin.</p>
            <p className="fm">
              <h3>Unsere Telefonzeiten:</h3>
              <div className="jc-sb">
                <div>Montag</div>
                <div>08:30 - 15:00</div>
              </div>
              <div className="jc-sb">
                <div>Dienstag</div>
                <div>08:30 - 15:00</div>
              </div>
              <div className="jc-sb">
                <div>Mittwoch</div>
                <div>08:30 - 12:30</div>
              </div>
              <div className="jc-sb">
                <div>Donnerstag</div>
                <div>08:30 - 15:00</div>
              </div>
              <div className="jc-sb">
                <div>Freitag</div>
                <div>08:30 - 12:30</div>
              </div>
            </p>
          </div>
          <div className="col end">
            <div className="footer-images-content">
              <div className="footer-image">
                <img src={footerimage1} alt="" />
              </div>
              <div className="footer-image">
                <img src={footerimage2} alt="" />
              </div>
            </div>
            <br />
            <br />
            <h3>RECHTLICHES</h3>
            <a className="a" href="/impressum">
              Impressum
            </a>
            <br />
            <a className="a" href="/datenschutz">
              Datenschutz
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
