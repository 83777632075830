import React from "react"
import { resetTitle } from "../components/main"

function Imprint() {
  resetTitle("Impressum")
  return (
    <div className="impressum-page container text-page">
<h1>Impressum</h1>

<p>Jan Schnitker<br />
Kohlenstr. 36<br />
44795 Bochum</p>

<h2>Kontakt</h2>
<p>Telefon: +49 234 5884498<br />
E-Mail: info@rueckhalt.net</p>

<h2>Aufsichtsbeh&ouml;rde</h2>
<p>Gesundheitsamt Bochum<br />
Westring 28/30<br />
44777 Bochum</p>
<p><a href="https://www.bochum.de/Gesundheitsamt" target="_blank" rel="noopener noreferrer">https://www.bochum.de/Gesundheitsamt</a></p>

<h2>Berufsbezeichnung und berufsrechtliche Regelungen</h2>
<p>Berufsbezeichnung:<br />
Physiotherapeut <br/>
Heilpraktiker (Physiotherapie)
</p>
<p>
IK: 442593580<br />
<br />
</p>
<p>Verliehen in:<br />
Deutschland</p>
<p>Es gelten folgende berufsrechtliche Regelungen:</p>
<p>MPGH<br />
 einsehbar unter: <a href="https://www.gesetze-im-internet.de/mphg/BJNR108400994.html" target="_blank" rel="noopener noreferrer">https://www.gesetze-im-internet.de/mphg/BJNR108400994.html</a></p>
<p>VSBG<br />
 einsehbar unter: <a href="https://www.gesetze-im-internet.de/heilprg/" target="_blank" rel="noopener noreferrer">https://www.gesetze-im-internet.de/heilprg/</a></p>
<h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
<p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
    </div>
  )
}

export default Imprint
