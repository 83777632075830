import React from "react"
import { IMPRESSUM, openOffcanvas, closeOffcanvas } from "./main"
import logo from "../images/logo.png"

function Header() {
  return (
    <div>
      <div className="media-buttons">
        <a type="button" href={"tel:" + IMPRESSUM.tel} className="btn red-outline">
        <i className="bi bi-telephone-fill"></i> {IMPRESSUM.tel}
        </a>
      </div>
      <div className="container text-center">
        <div className="row topheader">
          <div className="col">
            <a href="/">
              <img className="logo-in-header" src={logo} alt="logo" />
            </a>
          </div>
          <div className="text-end col button">
            <a type="button" href={"tel:" + IMPRESSUM.tel} className="btn red-outline">
            <i className="bi bi-telephone-fill"></i> {IMPRESSUM.tel}
            </a>
          </div>
          <div className="offcanvas-col col">
            <MenuOffcanvas />
          </div>
        </div>
      </div>
      <div className="bh">
        <HeaderMenu />
      </div>
    </div>
  )
}

function MenuOffcanvas() {
  return (
    <div>
      <a className="offcanvasMedia btn red-inline" href="/#" onClick={openOffcanvas} role="button">
        <i className="bi bi-list"></i>
      </a>
      <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvas" aria-labelledby="offcanvasLabel">
        <div className="offcanvas-header">
          <a type="button" href="/#" className="btn close-btn red-inline" data-bs-dismiss="offcanvas" aria-label="Close" id="close">
            <i className="bi bi-x-lg"></i>
          </a>
        </div>
        <div className="offcanvas-body">
          <HeaderMedia />
        </div>
      </div>
    </div>
  )
}

function HeaderMenu() {
  return (
    <div className="header">
      <div className="container text-center">
        <div className="row">
          <div className="col text-end">
            <div className="mt-3 mb-3 header-con">
              <a className="header-a" href="/#ihre-therapeuten">
                Ihre Therapeuten
              </a>
              <a className="header-a" href="/#manuelle-therapie">
                Manuelle Therapie
              </a>
              <a className="header-a" href="/#faq">
                Fragen & Antworten
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function HeaderMedia() {
  return (
    <div className="header">
      <div className="container text-center">
        <div className="row">
          <div className="col text-end">
            <div className="mt-3 mb-3 header-con">
              <a className="header-a" href="/#ihre-therapeuten" onClick={closeOffcanvas}>
                Ihre Therapeuten
              </a>
              <a className="header-a" href="/#manuelle-therapie" onClick={closeOffcanvas}>
                Manuelle Therapie
              </a>
              <a className="header-a" href="/#faq" onClick={closeOffcanvas}>
                Fragen & Antworten
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { Header }
