// App.js
import React from 'react';
import Home from './pages/Home';
import Team from './pages/Team';
import Error from './pages/404';
import Policy from './pages/Datenschutz';
import Imprint from './pages/Impressum';
import {Header} from './components/Header'
import Footer from "./components/Footer"
import './css/root.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';

const routes = {
  '/': <Home />,
  '/team': <Team />,
  '/impressum': <Imprint />,
  '/datenschutz': <Policy />,
  '*': <Error />
};

const App = () => {
  const getPage = () => {
    const path = window.location.pathname;
    return routes[path] || routes['*'];
  };

  return (
    <div className='App'>
      <main>
        <Header />
          {getPage()}
      </main>
      <Footer/>
    </div>
  );
}

export default App;
