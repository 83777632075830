import React from "react"
import { resetTitle } from "../components/main"
import profileImage1 from "../images/GOF03971-Bearbeitet.jpg"
import profileImage2 from "../images/GOF04008-Bearbeitet.jpg"

const Team = () => {
  resetTitle("Team")
  return (
    <div className="team">
      <TeamCards />
    </div>
  )
}

function TeamCards() {
  return (
    <div className="team-cards">
      <div className="container text-center">
        <h1>Ihre Therapeuten</h1>
        <div className="cards-group-headline">
          <div className="container">
            <p>
              Wir behandeln mit der Manuellen Therapie nach dem Maitland Konzept (DVMT) und der CRAFTA. Manualtherapeuten müssen eine zusätzliche,
              spezielle Ausbildung hierfür absolvieren und ein entsprechendes Zertifikat erwerben. Zu unserem Team gehören nur hochqualifizierte
              Manualtherapeuten, mit langjähriger Berufserfahrung. Aus diesem Grunde erhalten Sie in der Praxis Rueckhalt eine Behandlung auf höchstem
              Niveau.
              <br />
              <br />
              Um eine sehr hohe Qualität in unseren Behandlungen zu erreichen und das bestmögliche Ergebnis für unsere Patienten zu erzielen bilden
              sich mein Mitarbeiter und ich ständig weiter fort und suchen den Austausch mit Kollegen, Ärzten unterschiedlicher Fachrichtungen und
              anderen Professionen im Gesundheitswesen. Im Laufe vieler Jahre ist so ein interdisziplinäres Netzwerk entstanden.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="card">
              <img src={profileImage2} className="card-img-top" alt="" />
              <div className="card-body">
                <h3 className="card-title">Jan Schnitker B.A.</h3>
                <p className="card-text">
                  <br />
                  Seit vielen Jahren ist der Fachbereich der Manuellen Therapie die Basis für meine tägliche Arbeit. Meine mehrjährige Ausbildung als
                  Manualtherapeut, die kontinuierliche Fort- und Weiterbildung in diesem Fachbereich, einschließlich meines Bachelorstudiums bilden
                  für mich die Grundvoraussetzung, um Erkenntnisse aus der Wissenschaft in meine tägliche praktische Arbeit einfließen zu lassen. Im
                  Laufe meiner vielen Berufsjahre habe ich mich auf die Behandlung von akuten und chronischen Schmerzen und Dysfunktionen des
                  muskoluskelettalen Systems sowie der Kopf- und Kieferregion spezialisiert.
                  <br />
                  <br />
                  Ich verfolge als Manualtherapeut und sektoraler Heilpraktiker für Physiotherapie in meiner Praxis einen sehr hohen
                  wissenschaftlichen Standard und habe den Anspruch, meine Patienten zeitgemäß und bestmöglich zu behandeln. Die hohe Qualität der
                  Behandlung steht daher in meiner Praxis an erster Stelle.
                  <br />
                  <br />
                  <ul>
                    <li>Physiotherapeut</li>
                    <li>Bachelor of Arts für Medizinalfachberufe (B.A.)</li>
                    <li>Zertifizierter Manualtherapeut (DVMT, CRAFTA)</li>
                    <li>sektoraler Heilpraktiker für Physiotherapie</li>
                    <li>Sportphysiotherapeut (mit DOSB Anerkennung)</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <img src={profileImage1} className="card-img-top" alt="" />
              <div className="card-body">
                <h3 className="card-title">Matthias Winking</h3>
                <p className="card-text">
                  <br />
                  Ich habe mich für die Weiterqualifikation als Manualtherapeut entschieden, weil es für mich eine der effektivsten Therapieformen
                  ist, um die Schmerzen des Patienten zu lindern und die Lebensqualität zu verbessern. Durch meine mehrjährige Arbeit mit diesem
                  Konzept konnte ich viel Erfahrung in der Manuelle Therapie sammeln, welche ich meinen Patienten gerne zur Verfügung stelle.
                  <br />
                  <br />
                  <ul>
                    <li>Physiotherapeut</li>
                    <li>Zertifizierter Manualtherapeut (DVMT)</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Team
