import cardImage1 from "../images/patientengespruach-kluarung-symptome.jpg"
import cardImage2 from "../images/schmerzskala-schmerzanamnese.jpg"
import cardImage3 from "../images/FFG9JD64.jpg"
import cardImage4 from "../images/schmerztoleranzmessung-bearb.jpg"

import cardGImage1 from "../images/faszienbehandlung-tensigraty-modell.jpg"
import cardGImage2 from "../images/brustwirbelsaule-mobilisation-manuelle-therapie.jpg"
import cardGImage3 from "../images/headerbild+schadelmobilisation.jpg"
import cardGImage4 from "../images/mobilisation-brustbein.jpg"
import cardGImage5 from "../images/muskelbehandlung-triggerpunkt.jpg"
import cardGImage6 from "../images/rotationsmobilisation-halswirbelsaule.jpg"

function Cards() {
  return (
    <section id="manuelle-therapie">
      <div className="cards">
        <div className="cards-haedline">
          <div className="cards-group-headline">
            <div className="container">
              <h2>Manuelle Therapie</h2>
              <p>
                Die Manuelle Therapie nach unseren Behandlungskonzepten ist wissenschaftlich fundiert, liefert wichtige Erkenntnisse für Behandlungen
                des muskuloskelettalen Systems und ihre Effektivität wird durch zahlreiche nationale und internationale Studien belegt. <br />
                <br /> Die Manuelle Therapie befasst sich mit dem gezielten Untersuchen und Behandeln von Schmerzen und Dysfunktionen im Gelenk-,
                Muskel-, Nerven- und Faszien- System, unter Berücksichtigung der gesamten Körperstatik und den persönlichen körperlichen Gegebenheiten
                des Patienten. <br />
                <br />
                Durch die gezielte Behandlung von festgestellten Funktionsstörungen strebt die Manuelle Therapie die Wiederherstellung der normalen
                Bewegung und Funktion an, um Schmerzen zu lindern und die Lebensqualität des Patienten zu verbessern.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="card-left col">
            <img className="card-img" src={cardImage1} alt="" loading="lazy" />
          </div>
          <div className="col bg-p-1">
            <div className="card-text">
              <h2>Anamnese</h2>
              <br />
              <p>
                Die Anamnese ist ein wichtiger Bestandteil zur individuellen Befunderstellung. Hier erfolgt neben der Aufklärung auch eine
                ausführliche Befragung zur medizinischen Vorgeschichte, aktuellen Symptomen, früheren Verletzungen oder Operationen, beitragende
                Faktoren, Aktivitäten, mögliche Auslöser der Beschwerden, vorangegangene Behandlungen sowie eventuelle Begleiterkrankungen.
                <br />
                <br />
                Ziel der Anamnese ist es, Ihre individuellen Bedürfnisse und Ziele zu verstehen, potenzielle Ursachen für die vorliegenden Beschwerden
                zu finden, Risikofaktoren zu erkennen und auf alle Ihre Fragen, die im Zusammenhang mit Ihren Beschwerden und der Behandlung stehen,
                einzugehen. Dies ermöglicht es uns, einen ganzheitlichen und gezielten Behandlungsplan für Sie zu erstellen, um für Sie das
                bestmögliche Behandlungsergebnis zu erreichen.
              </p>
            </div>
          </div>
        </div>

        <div className="row row-img-right">
          <div className="card-left col col-sm-0">
            <img className="card-img" src={cardImage4} alt="" loading="lazy" />
          </div>
          <div className="col bg-p-2">
            <div className="card-text">
              <h2>Körperliche Untersuchung</h2>
              <br />
              <p>
                Im Anschluss an die Anamnese erfolgt die körperliche Untersuchung. Sie umfasst spezielle Tests, um Bewegungseinschränkungen,
                asymmetrische Bewegungsmuster und Dysfunktionen zu identifizieren. Dies umfasst das Prüfen der Beweglichkeit, Kraft, Stabilität und
                Koordination bestimmter Gelenke und Muskeln.
                <br />
                <br />
                Darüber hinaus werden Palpationstechniken angewendet, um Gewebestruktur, Muskelspannung, Gelenkbeweglichkeit und
                Schmerzempfindlichkeit zu bewerten.
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="card-left col col-sm-0">
            <img className="card-img" src={cardImage2} alt="" loading="lazy" />
          </div>
          <div className="col bg-p-1">
            <div className="card-text">
              <h2>Schmerzanalyse</h2>
              <br />
              <p>
                Die genaue Schmerzanalyse und Bewertung der Schmerzcharakteristik ist ein entscheidender Schritt, um die Ursachen Ihrer Schmerzen zu
                beurteilen und einzugrenzen.
                <br />
                <br />
                Dabei können verschiedene Methoden und Techniken angewendet werden (Pain Scale, Dolorimeter, Schmerzfragebogen u.a.) um Informationen
                über die Art, Dauer, Intensität, Qualität (stechend, dumpf, ziehend etc.) und Lokalisation Ihrer Schmerzen zu sammeln.
              </p>
            </div>
          </div>
        </div>

        <div className="row row-img-right">
          <div className="card-left col col-sm-0">
            <img className="card-img" src={cardImage3} alt="" loading="lazy" />
          </div>
          <div className="col bg-p-2">
            <div className="card-text">
              <h2>Funktionelle Untersuchung</h2>
              <br />
              <p>
                Die Analyse der Körperstatik und Bewegungsmuster ist ein wichtiger Bestandteil der ganzheitlichen Untersuchung, um Haltungsschwächen,
                muskuläre Dysbalancen und strukturelle Anomalien zu erkennen.
                <br />
                <br />
                Diese Analyse umfasst verschiedene Methoden und Techniken, um die Position und Ausrichtung des Körpers im Raum zu bewerten sowie
                potenzielle Ursachen für Beschwerden oder Funktionsstörungen aufzudecken.
                <br />
                <br />
                Durch spezielle Tests werden einzelne Organsysteme auf Dysfunktionen des muskuloskelettalen Bewegungsapparates hin überprüft, um zu
                bewerten, ob diese eine Mitbeteiligung an den Symptomen haben oder sie diese verursachen.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function CardsGroupHeadline() {
  return (
    <div className="cards-group-headline">
      <div className="container">
        <h2>Behandlung</h2>
        <p>
          Der Manualtherapeut erspürt Verspannungen, Bewegungseinschränkungen, Faszienverklebungen, Triggerpunkte, Blockaden und Gelenkinstabilitäten
          mit seinen Händen. Durch gezielte manualtherapeutische Techniken an Muskeln, Gelenken, Faszien und Nerven wird das Gewebe zur Heilung und
          Regeneration stimuliert und ein Gleichgewicht unter den einzelnen Organsystemen wieder hergestellt.
          <div className="container imageGroup text-center">
            <div className="row row-cols-1 row-cols-xs-1 row-cols-sm-1 row-cols-md-3 row-cols-xl-3 row-cols-xxl-3">
              <div className="col"><img className="ig-img" src={cardGImage3} alt="" /></div>
              <div className="col"><img className="ig-img" src={cardGImage1} alt="" /></div>
              <div className="col"><img className="ig-img" src={cardGImage4} alt="" /></div>
            </div>
          </div>
          Wir verfolgen in unseren Behandlungen immer einen ganzheitlichen Ansatz, der sowohl passive als auch aktive Techniken umfasst. Diese
          Behandlungstechniken werden individuell auf Ihre Bedürfnisse und Ziele abgestimmt und können je nach Diagnose und Zustand variieren. Unseren
          Patienten werden gezielte Korrekturmaßnahmen zur Statik, Beseitigung von muskulären Dysbalancen und Hilfestellungen für die Umsetzung im
          Alltag gegeben. Daher erstellen wir für Sie ein individuelles Heimprogramm, um die Genesung zusätzlich zur Behandlung zu unterstützen.
          <div className="container imageGroup text-center">
            <div className="row row-cols-1 row-cols-xs-1 row-cols-sm-1 row-cols-md-3 row-cols-xl-3 row-cols-xxl-3">
              <div className="col"><img className="ig-img" src={cardGImage5} alt="" /></div>
              <div className="col"><img className="ig-img" src={cardGImage6} alt="" /></div>
              <div className="col"><img className="ig-img" src={cardGImage2} alt="" /></div>
            </div>
          </div>
          Basierend auf den Ergebnissen aus der Anamnese und der körperlichen Untersuchung erstellen wir einen individuellen Behandlungsplan, der
          darauf abzielt, Funktionsstörungen am Bewegungsapparat zu korrigieren, Schmerzen zu lindern und die Beweglichkeit zu verbessern.
        </p>
      </div>
    </div>
  )
}

function CardsGroup() {
  return (
    <div className="CardsGroup">
      <div className="container text-center">
        <div className="row row-cols-1 row-cols-md-3 g-4">
          <div className="col">
            <div className="card h-100">
              <img src={cardGImage3} className="card-img-top" alt="..." />
              <div className="card-body">
                <h3 className="card-title">Card title</h3>
                <p className="card-text">
                  Durch gezielte manualtherapeutische Techniken an Muskeln, Gelenken, Faszien und Nerven wird das Gewebe zur Heilung und Regeneration
                  stimuliert und ein Gleichgewicht unter den einzelnen Organsystemen wieder hergestellt.
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <img src={cardGImage1} className="card-img-top" alt="..." />
              <div className="card-body">
                <h3 className="card-title">Card title</h3>
                <p className="card-text">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
                  erat. Lorem ipsum dolor sit amet, consetetur sadipscing elitr.
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <img src={cardGImage4} className="card-img-top" alt="..." />
              <div className="card-body">
                <h3 className="card-title">Card title</h3>
                <p className="card-text">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
                  erat. Lorem ipsum dolor sit amet, consetetur sadipscing elitr.
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <img src={cardGImage5} className="card-img-top" alt="..." />
              <div className="card-body">
                <h3 className="card-title">Card title</h3>
                <p className="card-text">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
                  erat. Lorem ipsum dolor sit amet, consetetur sadipscing elitr.
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <img src={cardGImage6} className="card-img-top" alt="..." />
              <div className="card-body">
                <h3 className="card-title">Card title</h3>
                <p className="card-text">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
                  erat. Lorem ipsum dolor sit amet, consetetur sadipscing elitr.
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card h-100">
              <img src={cardGImage2} className="card-img-top" alt="..." />
              <div className="card-body">
                <h3 className="card-title">Card title</h3>
                <p className="card-text">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
                  erat. Lorem ipsum dolor sit amet, consetetur sadipscing elitr.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { Cards, CardsGroupHeadline, CardsGroup }
