// Home.js
import React from "react"
import { resetTitle } from "../components/main"
import { Cards, CardsGroupHeadline  } from "../components/cards"

import heroImage from "../images/praxis-manuelle-therapie-bochum-rueckhalt-2.jpg"
import heroImageMedia from "../images/GOF04793-min.jpg"
import profileImage from "../images/GOF04976-4.png"

resetTitle("Home")

const Home = () => {
  return (
    <div className="home">
      <Hero />
      <Profile />
      <Cards />
      <CardsGroupHeadline />
      <Faq />
    </div>
  )
}

function Hero() {
  return (
    <div>
      <div className="hero">
        <div className="hero-title">
          <h1>Praxis für Manuelle Therapie und Schmerzbehandlung</h1>
        </div>
        <div className="hero-img">
          <img src={heroImage} alt="" loading="lazy" />
        </div>
      </div>
      <div className="hero-media">
        <div className="hero-img-media">
          <img src={heroImageMedia} alt="" loading="lazy" />
        </div>
        <div className="hero-title">
          <h1>Praxis für Manuelle Therapie und Schmerzbehandlung</h1>
        </div>
      </div>
    </div>
  )
}

function Profile() {
  return (
    <section id="ihre-therapeuten">
      <div className="profile">
        <div className="container text-center">
          <div className="row">
            <div className="col">
              <div className="profile-img col-sm-2">
                <img src={profileImage} alt="" loading="lazy" />
              </div>
            </div>
            <div className="col col-sm-6">
              <h2>Willkommen</h2>
              <h3>Bei uns stehen Sie als Patient im Vordergrund.</h3>
              <p>
                Wir sind Ihre erfahrenen Spezialisten für Manuelle Therapie, in modernen und hochwertig ausgestatteten Räumlichkeiten. Bei uns erfolgt
                die Behandlung immer individuell und ganzheitlich. Durch unseren „Rueckhalt“ unterstützen und begleiten wir Sie in unserer Praxis mit
                dem gesamten Team. Seit 2004 behandeln wir erfolgreich Privatpatienten, Selbstzahler und Patienten aller Kassen und
                Berufsgenossenschaften. Wenn Sie konkrete Fragen haben, beraten wir Sie und helfen Ihnen gerne weiter. 
                <br/><br/>
                Mein Team und ich freuen
                sich auf Ihren Besuch.
              </p>
              <div className="button-profile">
                <a className="profile-a btn red-inline" href="/team">
                  Mehr über uns
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function Faq() {
  return (
    <section id="faq">
      <div className="faq">
        <h1>Fragen & Antworten</h1>
        <div className="container text-center">
          <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="accordion-item">
              <h3 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  Wie erhalte ich einen Termin?
                </button>
              </h3>
              <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">
                  Da uns das persönliche Gespräch mit Ihnen sehr wichtig ist und wir auch im Vorfeld der Behandlung Ihre Heilmittelverordnung auf
                  Gültigkeit und Korrektheit überprüfen, erfolgt die Terminvereinbarung in der Praxis Rueckhalt ausschließlich telefonisch.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h3 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo"
                >
                  An was sollte ich zum ersten Behandlungstermin denken?
                </button>
              </h3>
              <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">
                  Bitte bringen Sie zur ersten Behandlung Ihre Verordnung, Ihre Krankenversichertenkarte, Ihre Befunde (wenn vorhanden) und ein
                  Handtuch mit. Um einen reibungslosen Ablauf Ihrer Behandlung durchführen zu können, sollten Sie bereits 10 Min. vor dem
                  Behandlungstermin anwesend sein.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h3 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                >
                  Kann ich auch ohne Heilmittelverordnung behandelt werden?
                </button>
              </h3>
              <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">
                  Es besteht die Möglichkeit auch ohne eine Heilmittelverordnung behandelt zu werden. Voraussetzung ist im Vorfeld eine
                  Diagnosestellung durch den Therapeuten (sektoraler Heilpraktiker für Physiotherapie). Diese dauert 60 Min., wofür ein extra Termin vereinbart wird. Die
                  Kosten für die Diagnosestellung und die anschließende Behandlung sind von Ihnen selbst zu tragen, da diese in der Regel nicht von
                  den Krankenkassen übernommen werden.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h3 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapse4"
                  aria-expanded="false"
                  aria-controls="flush-collapse4"
                >
                  Habe ich bei jedem Termin denselben Therapeuten ?
                </button>
              </h3>
              <div id="flush-collapse4" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">
                  Wir planen die Behandlung immer bei demselben Therapeuten, der auch den Eingangsbefund erstellt hat.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h3 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapse5"
                  aria-expanded="false"
                  aria-controls="flush-collapse5"
                >
                  Welche Diagnosen werden in der Praxis Rueckhalt behandelt?
                </button>
              </h3>
              <div id="flush-collapse5" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">
                  Die Praxis Rueckhalt hat ihren Behandlungsschwerpunkt in der Therapie von orthopädischen und chirurgischen Krankheitsbildern,
                  Sportverletzungen und allgemeinen Dysfunktionen und Schmerzen des Bewegungsapparates sowie der Kopf- und Kieferregion.
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h3 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapse6"
                  aria-expanded="false"
                  aria-controls="flush-collapse6"
                >
                   Ist die Praxis barrierefrei zu erreichen?
                </button>
              </h3>
              <div id="flush-collapse6" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">
                Die Praxisräume befinden sich im 1. OG und sind daher nicht barrierefrei zu erreichen.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Home
